import { BaseAPI } from 'api/auth';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { PageableParams } from 'hooks/useReactQueryHelpers';
import { PageableResponse } from 'api/interfaces/responses';
import { PaymentForecastsItemDto, PaymentForecastsClubSummaryDto } from './interfaces';
import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';

export async function fetchPaymentForecastsByFilter(params: PageableParams) {
  return BaseAPI.get<PageableResponse<PaymentForecastsItemDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-forecast/summary?page=${params.page}&size=${params.size}&filter=${params.filter}`,
  );
}

export async function fetchOnePaymentForecast({
  startDate,
  endDate,
  params,
}: {
  startDate: string;
  endDate: string;
  params: PageableParams;
}) {
  return BaseAPI.get<PageableResponse<PaymentForecastsClubSummaryDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-forecast?page=${params.page}&size=${params.size}&filter=${params.filter}&startDate=${startDate}&endDate=${endDate}`,
  );
}

export async function fetchPaymentForecastCasesByClub(
  paymentGroupNumber: string,
  clubUuid: string,
  params: PageableParams,
) {
  return BaseAPI.get<PageableResponse<CaseGeneralInfoDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-forecast/group/${paymentGroupNumber}/clubs/${clubUuid}/cases?page=${params.page}&size=${params.size}`,
  );
}

export async function sendReportViaEmail(data: {
  startDate: string;
  endDate: string;
  reportEntityType: string;
}) {
  return BaseAPI.patch(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/email?reportEntityType=${data.reportEntityType}&startDate=${data.startDate}&endDate=${data.endDate}`,
  );
}

export async function sendOneReportViaEmail(reportUuid: string) {
  return BaseAPI.patch(`${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/email?reportUuid=${reportUuid}`);
}

export async function downloadReport(data: {
  startDate: string;
  endDate: string;
  reportEntityType: string;
}) {
  return BaseAPI.get(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/download?reportEntityType=${data.reportEntityType}&startDate=${data.startDate}&endDate=${data.endDate}`,
    {
      responseType: 'blob',
    },
  );
}

export async function downloadOneReport(reportUuid: string) {
  return BaseAPI.get(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/download?reportUuid=${reportUuid}`,
    {
      responseType: 'blob',
    },
  );
}

export async function generateMonthlyReport() {
  return BaseAPI.post(`${ENV_BACKOFFICE_BASE_API_URL}/v1/payment-forecast/creation`);
}

export async function regenerateReport(data: { number: string; s3Path: string }) {
  return BaseAPI.post(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/reports/billing-request/${
      data.number
    }/file?fileName=${data.s3Path.replaceAll('/', '%2F')}`,
  );
}
